<template>
  <div style="height: 350px" class="w-100">
    <div class="container-loading w-100" v-show="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!loading" style="height: 350px" class="w-100">
      <apexchart type="area" height="400" :options="chartOptions" :series="chartSeries" />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  name: "chart4",
  components: {
    apexchart: VueApexCharts,
  },
  props: ['graph'],
  data() {
    return {
      loading: true,
      chartOptions: {
        colors: ["#009488", "#d31408"],
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 9,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          categories: [],
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      },
      chartSeries: [
        {
          name: "Ganhos",
          data: [],
        },
        {
          name: "Perdas",
          data: [],
        }
      ],
    };
  },

  methods: {
    async updateChartData() {
      let newChartSeries = [
        { name: "Ganhos", data: [] },
        { name: "Perdas", data: [] },
      ];
      let newCategories = [];

      for (let i = 0; i < this.graph.length; i++) {
        const element = this.graph[i];
        newCategories.push(moment(element.y).format("DD/MM/YYYY"));
        newChartSeries[0].data.push(element.gains);
        newChartSeries[1].data.push(element.losses);
      }

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: newCategories,
        },
      };
      this.chartSeries = newChartSeries;

      this.loading = false;
    },
  },

  watch: {
    graph: {
      handler: function () {
        this.updateChartData();
      },
      deep: true,
    },
  },

};
</script>


<style scoped>
.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
</style>
