<template>
  <div class="container" id="CRM-graphs">
    <BaseHeader title="Histórico CRM" :navigation="navigation"> </BaseHeader>
    <!-- filters -->
    <div class="grid-filters mt-4">
      <div class="add-link-body">
        <div class="label-flex">
          <img src="@/assets/img/icons/my-sales.svg"/>
          <label for="CRM">CRM</label>
        </div>
        <router-link
          v-if="!pipelines.length && verificUserLevel"
          :to="{ name: 'CRM' }"
          class="add-link link mb-0"
          >Deseja criar um CRM?</router-link
        >
      </div>
      <div class="add-link-body">
        <div class="label-flex">
          <img src="@/assets/img/icons/affiliate.svg" />
          <label for="Vendedor">Vendedor</label>
        </div>
        <router-link
          v-if="vendedores.length === 1 && level == 'admin'"
          :to="{ name: 'Usuarios' }"
          class="add-link link mb-0"
          >Adicionar um vendedor?</router-link
        >
      </div>
      <div class="d-flex" style="align-items: center;">
        <svg
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
            stroke="#4ea934"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3 10H21"
            stroke="#4ea934"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 2V6"
            stroke="#4ea934"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 2V6"
            stroke="#4ea934"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <label for="" style="margin: 0 !important;">Data</label>
      </div>
      <BaseSelect
        :selectModel="pipeline"
        :array="pipelines"
        trackname="title"
        track-by="id"
        selectLabel=""
        deselectLabel=""
        selectedLabel=""
        :allow-empty="false"
        :multiple="false"
        :taggable="false"
        :searchable="true"
        :watch="true"
        v-show="showComponentCrm"
        :internal-search="true"
        :clear-on-select="false"
        :close-on-select="true"
        :show-no-results="true"
        :hide-selected="true"
        @search="debounceCrm($event)"
        @callback="fetchPipeline($event)"
        @change="pipeline = $event"
      >
        <span style="font-size: 13px !important" slot="noResult"
          >Nenhum resultado encontrado.</span
        >
      </BaseSelect>
      <div v-if="level == 'seller'">
        <p class="fake-input" >
          {{ $store.getters.user.user.first_name }} {{ $store.getters.user.user.last_name }}
        </p>
      </div>
      <BaseSelect
        v-else
        id="vendedor"
        :selectModel="selected_vendedor"
        track-by="id"
        :disabled="loadingCRM == 'ranking'"
        :array="options_vendedor"
        specificType="sellers"
        placeholder="Pesquise pelo e-mail"
        trackname="email"
        :multiple="false"
        :searchable="true"
        v-show="showComponentVendedor"
        @search="debounceVendedor($event)"
        :loading="loadingVendedor"
        @callback="getVendedor($event)"
        @change="selected_vendedor = $event"
      >
      </BaseSelect>
      <div class="date-range">
        <div class="d-flex justify-content-end">
          <div style="width: 250px">
            <multiselect
              v-model="periodo"
              placeholder="Selecione o período"
              label="name"
              track-by="value"
              :options="periodos"
              @select="updateFilter()"
              :multiple="false"
              :taggable="true"
              class=""
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :allow-empty="false"
              >
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <!-- graficos -->
    <b-tabs class="grid-graph">
      <b-tab  @click="selecRequest('leads')" class="row-graph" title="Movimentações e leads">
        <div v-show="!loadingTabs.load1" style="display: flex; gap: 50px;">
          <div style="width: 50%;">
            <h3>Leads por board</h3>
            <Chart1
              v-if="!loadingTabs.load1 && loadingCRM == 'leads'"
              v-show="pipeline && current"
              :total_leads="total_leads"
              :tags="tags"
            />
          </div>
          <div style="width: 50%;">
            <h3>Movimentações por board</h3>
            <Chart2
              v-if="!loadingTabs.load1 && loadingCRM == 'leads' && this.tags && this.tags[0] && this.tags[0].name"
              v-show="pipeline && current"
              :current="current"
              :tags="tags"
            />
          </div>
        </div>
        <div v-if="loadingTabs.load1 === true" class="d-flex justify-content-center" style="height: 400px; align-items: center;">
          <b-spinner label="Loading..."></b-spinner>
        </div>          
      </b-tab>
      <b-tab v-if="flags.includes('report_gain_loss')" @click="selecRequest('report')" title="Relatório Ganhos/Perdas">
        
        <div class="container-report">
          <div class="content-report">
            <span class="reason-text">
              <span class="gain">Ganhos</span>
              <img
              src="@/assets/icons/icon-trend-up.svg"
              alt="CPL" />
            </span>
            <div v-if="!loadingTabs.load4">
              <Chart3
                v-if="loadingCRM == 'report'"
                :valuesChart="valuesGainChart"
                :label="currentGainLabel"
                :pipeline="pipeline.title"
                situation="ganhos"
              />  
            </div>
            <div class="d-flex justify-content-center" style="height: 400px; align-items: center;" v-else>
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </div>
          <div class="content-report">
            <span class="reason-text">
              <span class="loss">Perdas</span>
              <img
              src="@/assets/icons/icon-trend-down.svg"
              alt="CPL" />
            </span>
              <div v-if="!loadingTabs.load2">
                <Chart3
                v-if="loadingCRM == 'report'"
                :valuesChart="valuesChart"
                :label="currentLabel"
                :pipeline="pipeline.title"
                situation="perdas"
                />  
              </div>
            <div class="d-flex justify-content-center" style="height: 400px; align-items: center;" v-else>
              <b-spinner label="Loading..."></b-spinner>
            </div>   
          </div>
        </div>     
        <div class="container-report">
          <Chart4 ref="reasonGraph" :graph="graphReason" v-if="graphReason"/>
        </div>     

        <div
        v-show="!pipeline">
        <b-row
          style="
            grid-column: 1/-1;
            margin: 0 auto;
            width: 100%;
            padding: 0px !important;"
          class="Table-body justify-content-center mt-5"
          >
          <p class="nao-encontrado">Nenhum CRM foi encontrado</p>
        </b-row>
        </div>
      </b-tab>
      <b-tab @click="selecRequest('ranking'), visible.ranking = true" v-if="level !== 'seller' " title="Ranking de vendedor">
        <div v-show="!loadingTabs.load3">
          <Ranking
            :pipeline="pipeline"
            :tags="tags"
            ref="rankingGraph"
            @load="rankingLoad"
            >
          </Ranking>
        </div>
        <div  v-if="loadingTabs.load3" class="d-flex justify-content-center" style="height: 400px; align-items: center;">
          <b-spinner label="Loading..."></b-spinner>
        </div>  
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BaseView from "@/template/BaseView.vue";
import { setupDB, getCachedData, cacheData } from "@/store/indexedDB.js";
import Chart4 from "@/components/Pipeline/chart4";
import Chart3 from "@/components/Pipeline/chart3";
import Chart2 from "@/components/Pipeline/chart2";
import Chart1 from "@/components/Pipeline/chart1";
import Ranking from "@/components/Ranking/Ranking"
import Multiselect from "vue-multiselect";
import moment from "moment";
import _ from "lodash";

import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();

import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();

import ActivitieService from "@/services/resources/ActivitieService";
const serviceActivitie = ActivitieService.build();

export default {
  components: {
    BaseView,
    Chart1,
    Multiselect,
    Chart2,
    Chart3,
    Chart4,
    Ranking
  },

  data() {
    return {
      loadingCRM:"leads",
      graphReason: [],
      loadingTabs: {
        load1: true,
        load2: true,
        load3: true,
        load4: true
      },
      flags:[],
      pageCrm: "",
      searchCrm: "",
      showComponentCrm: true,
      navigation: [
        { link: "Estratégico", to: "/dynamicRoute/estrategico" },
        { link: "Histórico CRM", to: this.$route.fullPath },
      ],
      visible: {
        leads: false,
        report: false,
        ranking: false
      },
      valuesChart: [],
      currentLabel: [],
      valuesGainChart: [],
      currentGainLabel: [],
      stop: false,
      stop2: false,
      hasRoute: false,
      current: null,
      total_leads: null,
      recursos: this.$store.getters.recursos,
      level: this.$store.getters.user.user.level,
      pipeline: null,
      pipelines: [],
      vendedores: [],
      selected_vendedor: [],
      options_vendedor: [],
      client: {
        width: 0,
      },
      periodo: {
        value: 7,
        name: "7 dias",
      },
      periodos: [
        { value: 7, name: "Últimos 7 dias" },
        { value: 15, name: "Últimos 15 dias" },
        { value: 30, name: "Últimos 30 dias" },
      ],
      tags: [],
      searchVendedor: "",
      loadingVendedor: false,
      pageVendedor: 1,
      showComponentVendedor: true,
    };
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        // Ontem: [yesterday, yesterday],
        // "Última semana": [lastWeek, today],
        // "Este mês": [
        //   new Date(today.getFullYear(), today.getMonth(), 1),
        //   new Date(today.getFullYear(), today.getMonth(), 30),
        // ],
        // "Este ano": [
        //   new Date(today.getFullYear(), 0, 1),
        //   new Date(today.getFullYear(), 11, 31),
        // ],
        // "Último mês": [
        //   new Date(today.getFullYear(), today.getMonth() - 1, 1),
        //   new Date(today.getFullYear(), today.getMonth(), 0),
        // ],
        // "Todo período": [
        //   new Date(2015, 0, 1),
        //   new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        // ],
      };
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  watch: {
    selected_vendedor(e) {
      this.updateFilter();
    },
    async pipeline(e) {
      this.tags = [];
      this.getTags();
      await this.updateFilter();
      this.resolveUrl(e);
    },
  },
  methods: {
    rankingLoad(e){
      this.loadingTabs.load3 = e
    },
    debounceCrm: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentCrm();
      } else {
        this.searchCrm = query;
        this.pageCrm = 1;
        this.pipelines = [];
        this.stop2 = "";
        this.fetchPipeline(this.pageCrm, query);
      }
    }, 500),
    selecRequest(e){
      this.loadingCRM = e
      this.updateFilter(true)
    },
    resetComponentCrm() {
      this.pageCrm = 1;
      this.searchCrm = "";
      this.pipelines = [];
      this.stop2 = "";
      this.showComponentCrm = false;
      this.$nextTick(() => {
        this.showComponentCrm = true;
      });
    },
    fetchPipeline(page = 1, query = "") {
      if (this.stop2 == true) {
        return;
      }

      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };
      servicePipeline
        .search(data)
        .then((response) => {
          var pipelines = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));

          if (response.data.length < response.per_page) {
            this.stop2 = true;
          }
          this.pipelines = this.pipelines.concat(pipelines);
          if (response.current_page == 1 && !this.hasRoute) {
            this.pipeline = this.pipelines[0]
          }
        })
        .catch((err) => {
          // this.pipelines = [];
        });
    },

    async getTags() {
      if (!this.pipeline || !this.pipeline.tags || !this.pipeline.tags.length) {
        return
      }
      this.tags = await Promise.all(
        this.pipeline.tags.map(async (tag) => {
          try {
            const resp = await serviceTags.read(`/show/${tag}`);
            return resp;
          } catch (error) {
            return {
              color: "default",
              name: "Tag Excluida",
            }
          }
        })
      );
    },
    //----------------------------------------inicio
    debounceVendedor: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentVendedor();
      } else {
        this.searchVendedor = query;
        this.pageVendedor = 1;
        // this.options_vendedor = [];
        this.stopVendedor = false;
        this.getVendedor(this.pageVendedor, query);
      }
    }, 500),
    resetComponentVendedor() {
      this.pageVendedor = 1;
      this.searchVendedor = "";
      this.options_vendedor = [];
      this.stopVendedor = false;
      this.showComponentVendedor = false;
      this.$nextTick(() => {
        this.showComponentVendedor = true;
      });
    },
    getVendedor(page = 1, query = "") {
      if (this.stopVendedor) {
        return;
      }

      var data = {
        page: `${page}&order_by=email&order=ASC&search=${query}`,
      };

      serviceSeller
        .search(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stopVendedor = true;
          }
          this.options_vendedor = this.options_vendedor.concat(resp.data);
          let notSeller = {
            id: 0,
            email: "Todos os vendedores",
          };
          this.options_vendedor.unshift(notSeller);
          this.options_vendedor = this.options_vendedor.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
          this.getMe();
        })
        .catch((err) => {});
    },
    resolveUrl(index) {
      this.$router.replace({ name: "relatorio-crm", query: { id: index.id, name: index.title } });
      this.pipeline = index;
    },
    async updateFilter(fromTab) {
      if (!fromTab) {
        this.visible.leads = false
        this.visible.report = false
        this.visible.ranking = false
        this.loadingTabs.load1 = true
        this.loadingTabs.load2 = true
        this.loadingTabs.load3 = true
        this.loadingTabs.load4 = true
      }
      if(this.loadingCRM == 'leads' && !this.visible.leads){
        this.loadingTabs.load1 = true;
        var data = {
          user_id: this.selected_vendedor ? this.selected_vendedor.id : 0,
          pipeline_id: this.pipeline.id,
          data_inicio:moment().subtract(this.periodo.value,"days").format("YYYY-MM-DD"),
          data_fim: moment().add(1,"days").format("YYYY-MM-DD"),
          data_inicio_last: moment().subtract(this.periodo.value,"days").format("YYYY-MM-DD"),
        };
        data.id = "view";
        await  servicePipeline
          .createId(data)
          .then((response) => {
            this.visible.leads = true
            this.current = this.pipeline.tags.map((tag) => response.current.filter((item) => item.tag_id == tag).length)
            this.total_leads = this.pipeline.tags.map((tag) => response.total_leads.filter((item) => item.tag_id == tag).length)
            this.loadingTabs.load1 = false;
          })
          .catch((err) => {});
      } else if (this.loadingCRM == 'report' && !this.visible.report) {
        await this.getReport();
        await this.getGraph();
      }else if (this.loadingCRM == 'ranking' && !this.visible.ranking) {
        this.visible.ranking = true
        this.loadingTabs.load3 = true
        this.$refs.rankingGraph.initChart(this.pipeline.id, this.periodo.value);
      }
      
    },
    getGraph(){
      let data = {
        id: 'lead/reasonGraph',
        startDate: moment().subtract(this.periodo.value, "days").format("YYYY-MM-DD"),
        pipelineId: this.pipeline.id
      }

      if(this.level == 'seller'){
        data.userId = this.$store.getters.user.user.id 
      }
      else if(this.selected_vendedor && this.selected_vendedor.id !== 0 ){
        data.userId = this.selected_vendedor.id;
      }
      
      serviceActivitie
        .createId(data)
        .then((resp) => {
          this.graphReason = resp;
        })
    },
    getReport(){
      this.loadingTabs.load4 = true
      let dataGain = {
        id: 'lead/get-report',
        start_date:moment().subtract(this.periodo.value,"days").format("YYYY-MM-DD"),
        end_date: moment().add(1,"days").format("YYYY-MM-DD"),
        pipeline_id: this.pipeline.id
      }
      if(this.level == 'seller'){
        dataGain.user_id = this.$store.getters.user.user.id 
      }
      else if(this.selected_vendedor && this.selected_vendedor.id !== 0 ){
        dataGain.user_id = this.selected_vendedor.id;
      }
      serviceActivitie
      .createId(dataGain)
      .then((resp) => {
        if(resp && resp.done){

        let others = resp.done.filter((item) => item.count == 1)
        let main = resp.done.filter((item) => item.count > 1)

        if (others.length && main.length) {
          this.valuesGainChart = main.map((item) => item.count)
          this.valuesGainChart.push(others.length)
          this.currentGainLabel = main.map((item) => item.reason)
          this.currentGainLabel.push('Outros')
        } else {
          this.valuesGainChart = resp.done.map((item) => item.count)
          this.currentGainLabel = resp.done.map((item) => item.reason)
        }
        this.loadingTabs.load4 = false
      }

        if(resp && resp.inactive){
          let othersLoss = resp.inactive.filter((item) => item.count == 1)
          let mainloss = resp.inactive.filter((item) => item.count > 1)

          if (othersLoss.length && mainloss.length) {
            this.valuesChart = mainloss.map((item) => item.count)
            this.valuesChart.push(othersLoss.length)
            this.currentLabel = mainloss.map((item) => item.reason)
            this.currentLabel.push('Outros')
          } else {
            this.valuesChart = resp.inactive.map((item) => item.count)
            this.currentLabel = resp.inactive.map((item) => item.reason)
          }
          this.loadingTabs.load2 = false
          this.visible.report = true
        }
      
      })
      .catch((err) => {
        console.log(err);
      });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    sellerTrue(user) {
      this.vendedores.unshift({
        id: user.id,
        email: user.email,
      });
      this.vendedor = {
        id: user.id,
        email: user.email,
      };
    },
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    if(!this.flags.length){
      this.flags = await getCachedData("fetchFlags");
    }
    if (this.$store.getters.recursos.fluxo_vendas < 1) {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
    if (this.level == "seller") {
      this.sellerTrue(this.$store.getters.user.user);
    }
    if (this.$store.getters.user.user.level == "blogger") {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
    if(this.$route.query.id){
      this.hasRoute = true
      const idSelec = this.$route.query.id
      const nameSelec = this.$route.query.name
      var data = {
        page: `1&order_by=title&order=ASC&search=${nameSelec}`,
      };
      servicePipeline
        .search(data)
        .then((response) => {
          var pipelines = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));
          this.pipeline = pipelines.find((pipe) => pipe.id == idSelec)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.fake-input{
  text-transform: capitalize;
  padding: 11px 25px 15px 20px !important;
  border: 0.5px solid var(--white-medium) !important;
  border-radius: 10px !important;
}
.label-flex{
  display: flex;
  img {
    height: fit-content;
    margin: 0px 5px 0 0;
  }
  label{
    margin: 0 !important;
  }
}
.vue-daterange-picker {
  width: 100% !important;
}
.grid-filters {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 0 20px;
  padding: 20px;
  border: 1px solid var(--white-medium);
  border-radius: 10px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
.grid-filtersMobile {
  display: flex;
}
.grid-graphMobile {
  grid-template-columns: 1fr !important;
}
.grid-graph {
  h3 {
    color: var(--greenn);
    font-size: 16px;

  }
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .graph {
      width: 100%;
      &:nth-child(2) {
        margin-top: 170px;
      }
    }
  }
}
.row-graphs{
  >div:last-child {
    padding: 20px;
    border: 1px solid var(--white-medium);
    border-radius: 10px;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 15px;
  @media (max-width: 1055px) {
    display: grid;
    gap: 100px;
    justify-content: normal;
  }
}
@media (max-width: 768px) {
  .row-graphs {
    grid-template-columns: 1fr !important;
  }
}
.graph {
  .title-graph {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-bottom: 30px;
  }
}

</style>
<style lang="scss">
#CRM-graphs {
  .tab-content > .tab-pane {
    border: 1px solid var(--white-medium) !important;
    border-radius: 0 10px 10px;
    padding: 30px;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--greenn) !important;
    font-weight: 500 !important;
  }
  .nav-link:hover, .nav-link{
    border: 1px solid var(--white-medium) !important;
    border-right: none !important;
  }
  .nav-link.active, .nav-link.active:hover {
    border-bottom-color: white !important;
  }
  .nav-item a {
    color: var(--gray05);
    transition: color 0.3s;
    &:hover {
      color: var(--greenn);
    }
  }
  .nav-tabs {
    font-weight: 500 !important;
    border-bottom: none !important;
  }

  .nav-item a[aria-posinset="1"] {
    border-radius: 10px 0 0 0 !important;
  }

  .nav-item:last-child > a {
    border-radius: 0 10px 0 0 !important;
    border-right: 1px solid var(--white-medium) !important;
  }
  .content-report{
    border-radius: 10px;
    padding: 20px 60px;
    min-width: 29rem;
    width: 29rem;
    border: 1px solid var(--white-medium);
  }
  .container-report{
    width: 100%;
    display: flex !important;
    justify-content: space-around;
  }
  .reason-text{
    display: flex;
    .gain{
      color: var(--greenn);
    }
    .loss{
      color: var(--red);
    } 
    span{
      font-weight: 500;
    }
    align-items: center;
    gap: 5px;
  }
}
</style>
