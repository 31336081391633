import { render, staticRenderFns } from "./chart1.vue?vue&type=template&id=3ec52b82&scoped=true"
import script from "./chart1.vue?vue&type=script&lang=js"
export * from "./chart1.vue?vue&type=script&lang=js"
import style0 from "./chart1.vue?vue&type=style&index=0&id=3ec52b82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec52b82",
  null
  
)

export default component.exports