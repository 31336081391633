<template>
  <div>
    <div class="container-loading" v-show="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div class="container-funnel" v-show="!loading">
      <div class="container-label-funnel">
        <label id="label-board" v-for="(tag, index) in tags" :key="index">
          <span class="">{{ tag.name }}</span>
          <div class="legendValue d-flex align-items-center">
            <span class="mr-4 value_item_rel"
              >{{ total_leads[index] }} Leads</span
            >
            <div
              class="mark-label"
              :class="tag.color + '_color'"
            ></div>
            
          </div>
        </label>
      </div>
      <div class="funnel">
        <div
          class="container-data-funnel"
        >
          <div
            v-for="(tag, index) in tags"
            :key="index"
            class="item-funnel"
            :class="tag.color + '_color'"
          ></div>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  name: "chart1",
  props: ["total_leads", "tags"],
  data: () => {
    return {
      loading: false,
      data: {
        access: 0,
        started_payment: 0,
        payment_attempt: 0,
        payment_made: 0,
      },
    };
  },
};
</script>

<style scoped>
#label-board {
  height: 68px;
  max-height: 68px;
  margin-bottom: 0 !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.mark-label {
  margin: 8px 0;
  width: 24px;
  height: 4px;
}

.container-label-funnel > label {
  margin-bottom: 25px !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #81858e;
}
.container-label-funnel {
  width: 40%;
}
.container-funnel {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

@media (min-width: 600px) {
  .funnel {
    flex-grow: 0;
    max-width: 65%;
    flex-basis: 65%;
  }
}

.funnel {
  flex-grow: 0;
  flex-basis: 70%;
}

.container-data-funnel {
  width: 100%;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 58% 100%, 42% 100%);
}

.item-funnel span {
  margin: 0;
  font-size: 12px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.item-funnel {
  width: 100%;
  color: #fff;
  height: 68px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-bottom: 1px solid #fff;
  justify-content: center;
  border-radius: 3px;
}


.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}

.info_color {
  background: var(--bluee);
}
.danger_color {
  background: var(--red);
}
.warning_color {
  background: var(--orange);
}
.primary_color {
  background: var(--greenn-light);
}
.success_color {
  background: #2ed7ee;
}
.default_color {
  background: var(--gray01);
}
</style>
