<template>
  <div style="height: 350px" class="w-100">
    <div class="container-loading w-100" v-show="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-show="!loading" class="w-100">
      <apexchart
        ref="chart2"
        type="bar"
        :options="chartOptions"
        :series="chartSeries"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "chart",
  props: [
    "current",
    "tags",
  ],
  components: {
    apexchart: VueApexCharts,
  },
  data: () => {
    return {
      chart: null,
      loading: false,
      chartOptions: {
        colors: ["#4ea934"],
        chart: {
          height: 400,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 9,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      chartSeries: [
        {
          name: "Período atual",
          data: [],
        },
      ],
    };
  },
  mounted() {
    this.initChart();
  },
  computed: {},
  methods: {
    initChart() {
      this.chartOptions.xaxis.categories = this.tags.map((tag) => tag.name);
      this.chartSeries[0].data = this.current;
      this.chartSeries[0].name = 'Leads movimentados';
      this.$refs.chart2.refresh();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
</style>
