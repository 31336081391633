<template>
  <div class="w-100">
    <div v-if="!label.length">
      <p class="nao-encontrado">Não há histórico de {{ situation }} do CRM {{pipeline}} para esse período</p>
    </div>
    
    <apexchart
      v-else
      type="pie"
      height="400"
      :options="chartOptions"
      :series="valuesChart"
      ref="chart3"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "chart",
  props: [
    "valuesChart", "label", 'pipeline', 'situation'
  ],
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    chartOptions() {
      return {
        labels: this.label,
        colors: ['#2360A8', '#16C3B5', '#16D375', '#dfde44', '#ff9e44', '#d31408', '#d31488', '#8314bf'],
        chart: {
          height: 400,
          type: "pie",
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: 'bottom'
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
  .loss-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--greenn);
    border-radius: 10px;
    padding: 0px 0 20px 0 !important;
    background: transparent;
    margin: 0 !important;
  }
</style>
