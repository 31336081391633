<template>
  <div class="container">

    <div v-if="!$store.getters.recursos.nranking" class="__blocked">
      <svg fill="#4ea934" height="150px" width="150px" version="1.1" viewBox="0 0 330 330" ><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_509_"> <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path> </g> </g></svg>
      <span style="color: inherit; " class="title">Acesso bloqueado</span>
      <span style="font-size: 14px; color: inherit; opacity: 0.7;">Esse recurso ainda não está incluso em seu plano,<br> fale com nosso time comercial para mais informações.</span>
      <button @click="upgradePlan()">Fazer upgrade</button>
    </div>

    <div class="content" @mouseenter.once="$store.getters.recursos.nranking ? '' : disappear = true" v-if="!disappear">
      <div
      v-if="!loading_graph && !loading_ranking"
      class="graphic"
      >
      <apexchart
        type="bar"
        height="450px"
        ref="ranking"
        :options="chartOptions"
        :series="chartSeries"
        v-if="!loading_graph && !loading_ranking"
      />

      <div class="container users d-flex">
        <div class="d-flex users" style="margin-left: 86px">
          <div
            v-for="(user, index) in users"
            :key="index"
            class="card perfil"
            :class="{ 'first-user': index === 0 }"
            id="perfil"
            name="perfil"
            v-b-tooltip.hover
            :title="getUserName(user)"
          >
            <img
              v-if="getUserPhoto(user) !== 'avatar'"
              :src="getUserPhoto(user)"
              alt="user"
            />
            <div class="foto-usuario avatar-name" v-else>
              {{ getUserPhoto(user, "avatar") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="loading">
      <div class="d-flex mt-5 graph-container">
        <div class="d-flex skl" v-for="(x, index) in 10" :key="index">
          <b-skeleton width="25px" height="310px"></b-skeleton>
          <b-skeleton
            width="25px"
            height="210px"
            style="position: relative; bottom: -100px; margin-right: 48px"
          ></b-skeleton>
        </div>
      </div>
      <div class="d-flex skeleton-container mt-2">
        <div
          class="d-flex skl-photo-container"
          v-for="(x, index) in 10"
          :key="index"
        >
          <b-skeleton width="40px" height="40px" class="skl-photo"></b-skeleton>
        </div>
      </div>
    </div>

    <b-row  
      class="Table-header mb-1"
      v-if="!loading_graph && !loading_ranking"
    >
      <div class="table">
        <b-col class="d-none d-md-block"> Posição </b-col>

        <b-col class="d-none d-md-block"> Nome </b-col>

        <b-col
          class="d-none d-md-block"
          v-for="(item, index) in tags"
          :key="index"
        >
          {{ item.name }}
        </b-col>
      </div>
    </b-row>

    <div
      class="d-flex"
      style="gap: 40px; margin-top: 130px"
      v-else-if="loading"
    >
      <div class="d-flex" v-for="index in 12" :key="index">
        <b-skeleton width="65px" height="20px"></b-skeleton>
      </div>
    </div>

    <div :class="`${loading_ranking? 'mb-5' : ''}`" v-if="!loading_ranking && !loading_graph">
      <div class="Table-body-container">
        <template>
          <b-row
            class="Table-body"
            id="border-bottom"
            v-for="(item, index) in rows"
            :key="index"
          >
            <b-col class="d-none d-md-block" v-if="index == 0">
              <svg
                class="mr-2"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.38 3.76004C19.3458 3.63235 19.2867 3.51269 19.206 3.40798C19.1254 3.30328 19.0247 3.21561 18.91 3.15004L13.71 0.150039C13.5964 0.0835093 13.4709 0.0400392 13.3405 0.0221292C13.2101 0.00420916 13.0775 0.0122092 12.9502 0.0456492C12.823 0.0790992 12.7035 0.137329 12.5988 0.217019C12.4941 0.296709 12.4061 0.396289 12.34 0.510039L10 4.57004L7.65997 0.510039C7.59381 0.396289 7.50584 0.296709 7.40112 0.217019C7.2964 0.137329 7.17698 0.0790992 7.0497 0.0456492C6.92243 0.0122092 6.7898 0.00420916 6.65943 0.0221292C6.52906 0.0400392 6.40351 0.0835093 6.28997 0.150039L1.08997 3.15004C0.975201 3.21561 0.874571 3.30328 0.793901 3.40798C0.713231 3.51269 0.654111 3.63235 0.619971 3.76004C0.553791 4.01349 0.589691 4.28279 0.719971 4.51004L4.71997 11.34C4.27372 12.1576 4.02701 13.069 3.99997 14C3.99759 14.9083 4.20145 15.8053 4.59619 16.6233C4.99093 17.4413 5.56623 18.1591 6.27873 18.7224C6.99122 19.2857 7.82232 19.6799 8.7093 19.8753C9.5964 20.0707 10.5162 20.0621 11.3994 19.8502C12.2826 19.6384 13.1063 19.2288 13.8081 18.6523C14.51 18.0758 15.0719 17.3475 15.4513 16.5222C15.8308 15.697 16.0179 14.7964 15.9986 13.8883C15.9793 12.9802 15.7541 12.0884 15.34 11.28L19.24 4.52004C19.3126 4.40898 19.3623 4.28459 19.3864 4.15411C19.4104 4.02363 19.4082 3.88967 19.38 3.76004ZM2.99997 4.38004L6.45997 2.38004L9.68 8.00003C8.3074 8.08483 7.00676 8.64323 5.99997 9.58003L2.99997 4.38004ZM10 18C8.9391 18 7.92169 17.5786 7.17154 16.8284C6.4214 16.0783 5.99997 15.0609 5.99997 14C5.99997 12.9391 6.4214 11.9217 7.17154 11.1716C7.92169 10.4214 8.9391 10 10 10C11.0608 10 12.0783 10.4214 12.8284 11.1716C13.5785 11.9217 14 12.9391 14 14C14 15.0609 13.5785 16.0783 12.8284 16.8284C12.0783 17.5786 11.0608 18 10 18ZM14 9.55003C13.457 9.05413 12.8262 8.66413 12.14 8.40003L11.16 6.57004L13.58 2.38004L17.04 4.38004L14 9.55003Z"
                  fill="#FFD600"
                />
              </svg>

              {{ index + 1 }}º
            </b-col>

            <b-col class="d-none d-md-block" v-if="index == 1">
              <svg
                class="mr-2"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.38 3.76004C19.3458 3.63235 19.2867 3.51269 19.206 3.40798C19.1254 3.30328 19.0247 3.21561 18.91 3.15004L13.71 0.150039C13.5964 0.0835093 13.4709 0.0400392 13.3405 0.0221292C13.2101 0.00420916 13.0775 0.0122092 12.9502 0.0456492C12.823 0.0790992 12.7035 0.137329 12.5988 0.217019C12.4941 0.296709 12.4061 0.396289 12.34 0.510039L10 4.57004L7.65997 0.510039C7.59381 0.396289 7.50584 0.296709 7.40112 0.217019C7.2964 0.137329 7.17698 0.0790992 7.0497 0.0456492C6.92243 0.0122092 6.7898 0.00420916 6.65943 0.0221292C6.52906 0.0400392 6.40351 0.0835093 6.28997 0.150039L1.08997 3.15004C0.975201 3.21561 0.874571 3.30328 0.793901 3.40798C0.713231 3.51269 0.654111 3.63235 0.619971 3.76004C0.553791 4.01349 0.589691 4.28279 0.719971 4.51004L4.71997 11.34C4.27372 12.1576 4.02701 13.069 3.99997 14C3.99759 14.9083 4.20145 15.8053 4.59619 16.6233C4.99093 17.4413 5.56623 18.1591 6.27873 18.7224C6.99122 19.2857 7.82232 19.6799 8.7093 19.8753C9.5964 20.0707 10.5162 20.0621 11.3994 19.8502C12.2826 19.6384 13.1063 19.2288 13.8081 18.6523C14.51 18.0758 15.0719 17.3475 15.4513 16.5222C15.8308 15.697 16.0179 14.7964 15.9986 13.8883C15.9793 12.9802 15.7541 12.0884 15.34 11.28L19.24 4.52004C19.3126 4.40898 19.3623 4.28459 19.3864 4.15411C19.4104 4.02363 19.4082 3.88967 19.38 3.76004ZM2.99997 4.38004L6.45997 2.38004L9.68 8.00003C8.3074 8.08483 7.00676 8.64323 5.99997 9.58003L2.99997 4.38004ZM10 18C8.9391 18 7.92169 17.5786 7.17154 16.8284C6.4214 16.0783 5.99997 15.0609 5.99997 14C5.99997 12.9391 6.4214 11.9217 7.17154 11.1716C7.92169 10.4214 8.9391 10 10 10C11.0608 10 12.0783 10.4214 12.8284 11.1716C13.5785 11.9217 14 12.9391 14 14C14 15.0609 13.5785 16.0783 12.8284 16.8284C12.0783 17.5786 11.0608 18 10 18ZM14 9.55003C13.457 9.05413 12.8262 8.66413 12.14 8.40003L11.16 6.57004L13.58 2.38004L17.04 4.38004L14 9.55003Z"
                  fill="#B7B7B7"
                />
              </svg>

              {{ index + 1 }}º
            </b-col>

            <b-col class="d-none d-md-block" v-if="index == 2">
              <svg
                class="mr-2"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.38 3.76004C19.3458 3.63235 19.2867 3.51269 19.206 3.40798C19.1254 3.30328 19.0247 3.21561 18.91 3.15004L13.71 0.150039C13.5964 0.0835093 13.4709 0.0400392 13.3405 0.0221292C13.2101 0.00420916 13.0775 0.0122092 12.9502 0.0456492C12.823 0.0790992 12.7035 0.137329 12.5988 0.217019C12.4941 0.296709 12.4061 0.396289 12.34 0.510039L10 4.57004L7.65997 0.510039C7.59381 0.396289 7.50584 0.296709 7.40112 0.217019C7.2964 0.137329 7.17698 0.0790992 7.0497 0.0456492C6.92243 0.0122092 6.7898 0.00420916 6.65943 0.0221292C6.52906 0.0400392 6.40351 0.0835093 6.28997 0.150039L1.08997 3.15004C0.975201 3.21561 0.874571 3.30328 0.793901 3.40798C0.713231 3.51269 0.654111 3.63235 0.619971 3.76004C0.553791 4.01349 0.589691 4.28279 0.719971 4.51004L4.71997 11.34C4.27372 12.1576 4.02701 13.069 3.99997 14C3.99759 14.9083 4.20145 15.8053 4.59619 16.6233C4.99093 17.4413 5.56623 18.1591 6.27873 18.7224C6.99122 19.2857 7.82232 19.6799 8.7093 19.8753C9.5964 20.0707 10.5162 20.0621 11.3994 19.8502C12.2826 19.6384 13.1063 19.2288 13.8081 18.6523C14.51 18.0758 15.0719 17.3475 15.4513 16.5222C15.8308 15.697 16.0179 14.7964 15.9986 13.8883C15.9793 12.9802 15.7541 12.0884 15.34 11.28L19.24 4.52004C19.3126 4.40898 19.3623 4.28459 19.3864 4.15411C19.4104 4.02363 19.4082 3.88967 19.38 3.76004ZM2.99997 4.38004L6.45997 2.38004L9.68 8.00003C8.3074 8.08483 7.00676 8.64323 5.99997 9.58003L2.99997 4.38004ZM10 18C8.9391 18 7.92169 17.5786 7.17154 16.8284C6.4214 16.0783 5.99997 15.0609 5.99997 14C5.99997 12.9391 6.4214 11.9217 7.17154 11.1716C7.92169 10.4214 8.9391 10 10 10C11.0608 10 12.0783 10.4214 12.8284 11.1716C13.5785 11.9217 14 12.9391 14 14C14 15.0609 13.5785 16.0783 12.8284 16.8284C12.0783 17.5786 11.0608 18 10 18ZM14 9.55003C13.457 9.05413 12.8262 8.66413 12.14 8.40003L11.16 6.57004L13.58 2.38004L17.04 4.38004L14 9.55003Z"
                  fill="#8D5D00"
                />
              </svg>

              {{ index + 1 }}º
            </b-col>

            <b-col class="d-none d-md-block" v-if="index >= 3">
              {{ index + 1 }}º
            </b-col>

            <b-col class="d-none d-md-block">
              {{ item.user_name }}
            </b-col>
            <b-col
              v-for="(item2, index2) in item.tags"
              :key="index2"
              class="d-none d-md-block"
            >
              {{ item2.lead_count }}
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment-timezone";
import RankingService from "@/services/resources/RankingService";
const serviceRanking = RankingService.build();
import _ from "lodash";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props:["pipeline","tags"],
  data() {
    return {
      stop: false,
      chartSeries: [
        {
          name: "Ganho",
          data: [],
        },
        {
          name: "Perdido",
          data: [],
        },
      ],
      chartOptions: {
        colors: ["#4ea934", "#d31408"],
        chart: {
          type: "Bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["", "", "", "", "", "", "", "", "", ""],
        },
        yaxis: {
          title: {
            text: "Negociações Fechadas",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      rows: null,
      loading: false,
      users: [],
      selectedPipeline: null,
      loading_ranking: false,
      loading_graph: false,
      searchCrm: "",
      disappear: false
    };
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    createRanking(id, e) {
      this.loading_ranking = true;
      this.$emit('load', true);
      var data = {
        pipeline_id: id,
        start_date: moment().subtract(e, "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      };
      serviceRanking.create(data).then((resp) => {
        this.loading_ranking = false;
        this.rows = resp.rows;
        this.$emit('load', false);
      });
    },
    getGraph(id, e) {
      this.loading_graph = true;
      this.$emit('load', true);
      this.chartSeries[0].data = [];
      this.chartSeries[1].data = [];
      
      var data = {
        id: "graph",
        pipeline_id: id,
        start_date: moment().subtract(e, "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      };
      serviceRanking
        .createId(data)
        .then((resp) => {
          this.chartSeries[0].data = resp.earnings
          this.chartSeries[1].data = resp.lost
          this.users = resp.users;
        })
        .finally(() => {
          this.loading_graph = false;
          this.loading = false;
          this.$emit('load', false);
        });
    },
    getUserPhoto(user, no_photo = false) {
      var user_find = this.rows.find((x) => x.user_id == user);
      let photo;
      if (no_photo) {
        return user_find.user_name[0] + user_find.user_last_name[0];
      }
      if (user_find && user_find.photo) {
        photo = user_find.photo;
      } else{
        photo = "avatar";
      }

      return photo;
    },
    getUserName(user) {
      var users = this.rows;
      var user_find = users.find((x) => x.user_id == user);
      if (user_find) {
        return `${user_find.user_name}${user_find.user_last_name}`
      }
    },
    initChart(id, days){
      this.getGraph(id, days);
      this.createRanking(id, days)
    },
    upgradePlan() {
        $crisp.push(["set", "session:segments", [["financeiro"]]]);
        $crisp.push(["do", "chat:open"]);
        $crisp.push([
            "do",
            "message:send",
            ["text", `Quero fazer um upgrade no meu plano e ter acesso ao CRM.`],
        ]);
    },
  },
};
</script>

<style scoped>

.container{
  position: relative;
}
.content{
  position: relative;
  z-index: 1;
}

.Table-body,
.Table-header {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 15px 0px !important;
}

.table {
  display: flex;
}

.perfil {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.perfil img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.users {
  top: -25px;
  position: relative;
  width: 100%;
  gap: 75px;
  z-index: 999 !important;
}
.foto-usuario {
  display: grid;
  place-items: center;
  padding-top: 8px;
  text-transform: uppercase;
}
.first-user {
  margin-left: -13px;
}

.skl {
  gap: 10px;
}

.skl-photo {
  border-radius: 50%;
  display: flex;
}

.skl-photo-container {
  top: 50px;
  gap: 20px;
  position: relative;
}

.skeleton-container {
  position: relative;
  top: 0px;
  gap: 70px;
  margin-left: 78px;
}

.graph-container {
  position: relative;
  left: 70px;
  top: 40px;
}

.foto-usuario.avatar-name {
  background: #81858e;
  border-radius: 100%;
  height: 100%;
  padding-top: 3px;
  color: white;
  font-weight: 600;
  font-size: 18px;
}

/* Estilos opcionais para o componente */
</style>
